import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40cc6c7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_button = _resolveComponent("tab-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createBlock(_component_tab_button, {
        key: tab.title,
        icon: tab.icon,
        title: tab.title,
        index: _ctx.tabs.indexOf(tab),
        number: _ctx.number
      }, null, 8, ["icon", "title", "index", "number"]))
    }), 128))
  ]))
}