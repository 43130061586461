<template>
  <div class="links">
<!--    <a href="/under-construction">Privacy Settings</a>-->
    <a href="/contact-us">Customer Support</a>
    <a href="https://www.termsfeed.com/live/90fb3941-1658-43b9-a52f-8930c0c53468">Cookie Policy</a>
    <a href="https://www.termsandconditionsgenerator.com/live.php?token=YR8lovLenQLl3sPAIJGF1AnKLNbtEK4Y">Terms and Conditions</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LinkContainer',
});
</script>

<style scoped lang="scss">
.links {
  display: flex;
  flex-direction: row;
  gap: 30px;

  justify-self: center;

  a {
    text-decoration: none;
    color: $ai-color-white;

    &:hover {
      color: $ai-color-gray;
    }
  }
}
</style>
