<template>
  <a class="cta" :href="cta.url">{{ cta.title }}</a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CTAButton',
  props: {
    cta: Object,
    icon: String,
  },
});
</script>

<style scoped lang="scss">
.cta {
  display: flex;
  flex-direction: row;

  border-radius: 100px;
  padding: 15px 25px;

  width: fit-content;

  text-align: center;
  text-decoration: none;
  font-weight: 700;

  color: $ai-color-white;
  background-color: $ai-color-secondary;
  box-shadow: $ai-color-secondary-shadow;
}
</style>
