<template>
  <div class="home">
    <banner-view/>

    <block-view image-side="right" :info="block_1"/>
    <simple-block-view :info="block_2"/>
    <block-slides-view image-side="left" :info="block_3"/>
    <block-tabs-view image-side="left" :info="block_4"/>
    <block-view image-side="left" :info="block_5"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BannerView from '@/components/BannerView.vue';
import BlockView from '@/components/blocks/BlockView.vue';
import BlockTabsView from '@/components/blocks/BlockTabsView.vue';
import BlockSlidesView from '@/components/blocks/BlockSlidesView.vue';
import SimpleBlockView from '@/components/blocks/SimpleBlockView.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    SimpleBlockView,
    BlockSlidesView,
    BlockTabsView,
    BlockView,
    BannerView,
  },
  data() {
    return {
      block_1: {
        title: 'What is Edge AI?',
        body: 'Edge computing allows computations to be shifted to local devices, Internet of things (IoT) nodes, or an edge server. For hotels, this means that data-heavy information can be processed locally, rather than in the cloud. Edge AI brings artificial intelligence, therefore, to the edge. This enables the deployment of AI models to be close to their data source, thus, reducing processing latency and allowing real-time data processing. Subsequently, operational decisions can be taken in a timely and efficient manner to maximize productivity and long-term planning.',
        image: require('@/assets/home/cover.png'),
        cta: undefined,
      },
      block_2: {
        title: 'RoomVision',
        body: 'Aility brings artificial intelligence to the hospitality industry through edge devices/sensors. By reinventing the core of hospitality, we simplify back-of-house operations, remove redundant procedures, and create valuable data insights for effective data-driven decisions.',
        image: 'Rethinking Hotel Operations through <br/> Artificial Intelligence',
        cta: undefined,
      },
      block_3: {
        title: 'Aility Hospitality Solutions',
        image: undefined,
        tabs: [
          {
            icon: undefined,
            title: 'RoomVisioner',
            image: require('@/assets/home/room_visioner.png'),
            body: 'Through our proprietary sensor, we can provide meaningful insights into daily hotel operations through intelligent people flow analytics. We gather data points that have never existed before, up to this date. The sensor is able to make real-time operational suggestions and create predictions for better planning.',
            url: undefined,
          },
          {
            icon: undefined,
            title: 'HallwayVisioner',
            image: require('@/assets/home/hallway.jpg'),
            body: 'Our innovative software seamlessly integrates with CCTV cameras to revolutionize occupancy detection, optimize operations, and enhance efficiency in various settings.',
            url: undefined,
          },
          {
            icon: undefined,
            title: 'VisionAnalytics',
            image: require('@/assets/home/bi_tool.jpg'),
            body: 'VisionAnalytics is an easy way to understand what\'s happening on the floors in your hotel and beyond. By using our newly created data points, and in combination with existing data points, we create data analytics that are actionable for all managers.',
            url: undefined,
          },
        ],
      },
      block_4: {
        title: 'Get your Insights with RoomVision',
        image: undefined,
        tabs: [
          {
            icon: '&#xEA40;',
            title: 'Owners / Asset Managers',
            image: require('@/assets/roles/owners.png'),
            body: 'Owners and Asset Managers require a real-time overview of their assets within the hotel at any given point in time. With Aility\'s insights, we enable quick and easy understanding of their assets, enabling short- and long-term decision-making based on insightful data.',
            url: '/under-construction',
          },
          {
            icon: '&#xE4FC;',
            title: 'Operators',
            image: require('@/assets/roles/operators.png'),
            body: 'In today\'s day and age, operators should be able to get a holistic overview of their operations while clearly identifying bottlenecks and opportunities. By combining our proprietary hardware and software, operators can do just that!',
            url: '/under-construction',
          },
          {
            icon: '&#xE28D;',
            title: 'Employees',
            image: require('@/assets/roles/employees.png'),
            body: 'Working in hotels can be stressful and tiring, and at times even dangerous. Did you know that 9 out of 10 female employees in the hospitality industry have been sexually harassed? Through our real-time data, we relieve the stress of employees while maintaining higher security for them.',
            url: '/under-construction',
          },
          {
            icon: '&#xE7FD;',
            title: 'Guests',
            image: require('@/assets/roles/guests.png'),
            body: 'Remember the time you were last in a hotel and wanted to sleep in or have a business meeting, but then got disrupted by a “knock, knock - Housekeeping”? We eliminate unwanted guest contact by creating a flawless service experience, leaving more time to care about the guest and what truly matters to them!',
            url: '/under-construction',
          },
        ],
      },
      block_5: {
        title: undefined,
        body: 'Our proprietary sensor allows us to generate relevant comprehensions of hotel operations that have not been accessible before. This enables every manager to optimize on data-driven decisions, reducing costs, increasing revenues and enhancing overall productivity. Each gathered data point is visualized and put into context within our B.I. Tool to maximize the benefit for each of the above-mentioned departments individually. Want to know more?',
        image: require('@/assets/home/bi_tool.jpg'),
        cta: {
          title: 'Contact Us',
          url: '/contact-us',
        },
      },
    };
  },
});
</script>
