<template>
  <div class="tab-bar">
    <tab-button
      v-for="tab in tabs"
      :key="tab.title"
      :icon="tab.icon"
      :title="tab.title"
      :index="tabs.indexOf(tab)"
      :number="number"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabButton from '@/components/items/TabButton.vue';

export default defineComponent({
  name: 'TabBar',
  components: {
    TabButton,
  },
  props: {
    tabs: Array,
    number: Number,
  },
});
</script>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
  gap: 25px;
}
</style>
