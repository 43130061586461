<template>
  <div class="container left">
    <div class="text">
      <h1 v-if="info.title !== undefined">{{ info.title }}</h1>
      <tab-bar :tabs="info.tabs" :number="1"/>
      <p>{{ details.body }}</p>
    </div>

    <div class="image" :style="{'backgroundImage': `url(${details.image})`}"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabBar from '@/components/items/TabBar.vue';

export default defineComponent({
  name: 'BlockSlidesView',
  components: { TabBar },
  props: {
    info: Object,
  },
  data() {
    return {
      details: this.info?.tabs !== undefined ? this.info?.tabs[0] : Object,
    };
  },
  mounted() {
    const tabs = [...document.getElementsByClassName('tab-bar')[0].children];
    tabs[0].classList.add('selected');

    this.$store.watch(
      (state) => state.current_tab_1,
      // eslint-disable-next-line no-return-assign
      (value) => {
        if (this.info?.tabs !== undefined) this.details = this.info?.tabs[value];

        tabs.map((el) => el.classList.remove('selected'));
        tabs[value].classList.add('selected');
      },
    );
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  height: 400px;

  div {
    display: flex;

    gap: 25px;

    background-color: $ai-color-primary-light;
  }

  .text {
    display: flex;
    flex-direction: column;

    padding: 40px;
    width:calc(50% - 80px);

    text-align: left;
  }

  .image {
    display: block;

    height: 100%;
    width: 50%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

h1,
p {
  margin: 0;
}

p {
  text-align: justify;
}

.left {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1175px) {
  .container {
    height: auto;

    & > .text,
    & > .image {
      width: 100%;
      height: 300px;
    }
  }
}

@media screen and (max-width: 593px) {
  .container {
    & > .text {
      height: 350px;
    }
  }
}
</style>
