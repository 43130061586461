<template>
  <nav :class="theme">
    <router-link class="router-link" to="/"> Home </router-link>
    <router-link class="router-link" to="/solutions"> Solutions </router-link>
    <router-link class="router-link" to="/team"> Team </router-link>
    <router-link class="router-link" to="/contact-us"> Contact Us </router-link>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: (p) => p === 'dark' || p === 'light',
    },
  },
});
</script>

<style lang="scss">
nav {
  display: flex;
  flex-direction: row;
  gap: 20px;

  justify-self: center;

  &.dark > a {
    text-decoration: none;
    color: $ai-color-black;
    font-weight: 600;

    &:hover {
      color: $ai-color-secondary;
    }
  }

  &.light > a {
    text-decoration: none;
    color: $ai-color-white;
    font-weight: 600;

    &:hover {
      color: $ai-color-gray;
    }
  }
}

.top > .router-link-active {
  color: $ai-color-secondary !important;
}

@media screen and (max-width: 446px) {
  .top {
    position: absolute;
    display: none;
    flex-flow: column;

    align-items: center;
    justify-content: center;

    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: $ai-color-black;

    & > {
      .router-link {
        color: white;
      }
    }
  }
}
</style>
