<template>
  <div class="container">
    <div class="header">
      <h1>{{ info.title }}</h1>
      <h3 v-html="info.image"/>
    </div>

    <p>{{ info.body }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SimpleBlockView',
  props: {
    info: Object,
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 25px;

  padding: 60px 10px;

  .header {
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 10px;
  }

  p {
    width: 40%;
  }

  h3 {
    color: $ai-color-gray;
    font-weight: 200;
  }
}

h1,
h3,
p {
  margin: 0;
}
</style>
