import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06c80007"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "details" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_bar = _resolveComponent("tab-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.info.title), 1),
      _createVNode(_component_tab_bar, {
        tabs: _ctx.info.tabs,
        "is-divided": true,
        number: 2
      }, null, 8, ["tabs"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.details.image,
        alt: "image"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_ctx.details.body), 1)
      ])
    ])
  ]))
}