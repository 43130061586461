<template>
  <span :id="index" class="tab-bar-button" @click="changeTab">
    <span v-if="icon !== undefined" v-html="icon" class="material-symbols-rounded"/>
    <span>{{ title }}</span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TabButton',
  props: {
    title: String,
    index: Number,
    icon: String,
    number: Number,
  },
  methods: {
    changeTab() {
      this.$store.commit(`SELECTED_TAB_${this.number}`, this.index);
    },
  },
});
</script>

<style scoped lang="scss">
.tab-bar-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;
  border: 3px solid #1C1B1F;
  border-radius: 100px;
  padding: 10px 20px;

  width: fit-content;

  text-decoration: none;
  font-weight: 700;

  background: transparent;
  cursor: pointer;

  &.selected,
  &:hover {
    padding: 13px 23px;

    border: none;
    color: $ai-color-white;
    background-color: $ai-color-secondary;
    box-shadow: $ai-color-secondary-shadow;
  }
}
</style>
