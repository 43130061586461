<template>
  <div class="banner">
    <div class="nav">
      <img :src="image" alt="aility logo">
      <nav-bar theme="light"/>
    </div>

    <div class="links-container">
      <p id="copyright">&copy; {{ year }} Aility GmbH. All Rights Reserved.</p>
      <link-container/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/items/NavBar.vue';
import LinkContainer from '@/components/items/LinkContainer.vue';

export default defineComponent({
  name: 'FooterView',
  components: { LinkContainer, NavBar },
  data() {
    return {
      image: require('@/assets/branding/logo_white.svg'),
      year: new Date().getFullYear(),
    };
  },
});
</script>

<style scoped lang="scss">
.banner {
  display: flex;
  flex-direction: column;

  .nav {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 50px;

    color: $ai-color-white;
    background: $ai-color-primary;

    img {
      width: 110px;
    }
  }

  .links-container {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 10px 50px;

    font-size: 14px;

    color: $ai-color-white;
    background-color: $ai-color-primary-dark;
  }

  @media screen and (max-width: 906px) {
    .nav,
    .links-container {
      flex-direction: column;
    }

    .nav {
      gap: 20px;
    }
  }
}
</style>
