import { createStore } from 'vuex';

export default createStore({
  state: {
    current_tab_1: Number,
    current_tab_2: Number,
  },
  getters: {
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    SELECTED_TAB_1: (state, payload) => state.current_tab_1 = payload,
    // eslint-disable-next-line no-return-assign
    SELECTED_TAB_2: (state, payload) => state.current_tab_2 = payload,
  },
  actions: {
  },
  modules: {
  },
});
