import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner_view = _resolveComponent("banner-view")!
  const _component_block_view = _resolveComponent("block-view")!
  const _component_simple_block_view = _resolveComponent("simple-block-view")!
  const _component_block_slides_view = _resolveComponent("block-slides-view")!
  const _component_block_tabs_view = _resolveComponent("block-tabs-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_banner_view),
    _createVNode(_component_block_view, {
      "image-side": "right",
      info: _ctx.block_1
    }, null, 8, ["info"]),
    _createVNode(_component_simple_block_view, { info: _ctx.block_2 }, null, 8, ["info"]),
    _createVNode(_component_block_slides_view, {
      "image-side": "left",
      info: _ctx.block_3
    }, null, 8, ["info"]),
    _createVNode(_component_block_tabs_view, {
      "image-side": "left",
      info: _ctx.block_4
    }, null, 8, ["info"]),
    _createVNode(_component_block_view, {
      "image-side": "left",
      info: _ctx.block_5
    }, null, 8, ["info"])
  ]))
}