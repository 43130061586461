import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64b9c1e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CTAButton = _resolveComponent("CTAButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.imageSide, "container"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.info.title !== undefined)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.info.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.info.body), 1),
      (_ctx.info.cta !== undefined)
        ? (_openBlock(), _createBlock(_component_CTAButton, {
            key: 1,
            cta: _ctx.info.cta
          }, null, 8, ["cta"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle({'backgroundImage': `url(${_ctx.info.image})`})
    }, null, 4)
  ], 2))
}