<template>
  <div class="header">
    <router-link to="/">
      <img :src="logoExtended" alt="aility logo">
    </router-link>

    <nav-bar class="top"/>
    <a @click="toggleMenu" class="hamburger">
      <img :src="require('@/assets/home/menu.svg')" alt="menu icon"/>
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import NavBar from '@/components/items/NavBar.vue';

export default defineComponent({
  name: 'HeaderView',
  components: { NavBar },
  props: {
    msg: String,
  },
  data() {
    return {
      logoExtended: require('@/assets/branding/logo_extended.svg'),
    };
  },
  methods: {
    toggleMenu() {
      const top = document.querySelector('.top');
      const img = document.querySelector('.hamburger > img');

      if (!top.classList.contains('open')) {
        top.style.display = 'flex';
        top.classList.add('open');
        img.src = require('@/assets/home/close.svg');
        document.querySelector('#app').style.overflow = 'hidden';
      } else {
        top.style.display = 'none';
        top.classList.remove('open');
        img.src = require('@/assets/home/menu.svg');
        document.querySelector('#app').style.overflow = 'auto';
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      const top = document.querySelector('.top');
      const img = document.querySelector('.hamburger > img');

      if (window.innerWidth > 446) {
        top.style.display = 'flex';
      } else {
        top.style.display = 'none';
        top.classList.remove('open');
        img.src = require('@/assets/home/menu.svg');
        document.querySelector('#app').style.overflow = 'auto';
      }
    });
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;

  margin: 35px;

  img {
    height: 25px;
  }
}

.hamburger {
  display: none;
}

@media screen and (max-width: 446px) {
  .header {
    justify-content: space-between;

    & > .hamburger {
      display: block;
      z-index: 1;
    }
  }
}
</style>
