<template>
  <div :class="imageSide" class="container">
    <div class="text">
      <h1 v-if="info.title !== undefined">{{ info.title }}</h1>
      <p>{{ info.body }}</p>
      <CTAButton v-if="info.cta !== undefined" :cta="info.cta"/>
    </div>

    <div class="image" :style="{'backgroundImage': `url(${info.image})`}"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CTAButton from '@/components/items/CTAButton.vue';

export default defineComponent({
  name: 'BlockView',
  components: { CTAButton },
  props: {
    imageSide: {
      type: String,
      required: true,
      validator: (p) => p === 'left' || p === 'right',
    },
    info: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  height: 400px;

  div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 25px;

    width: 50%;

    background-color: $ai-color-primary-light;
  }

  .text {
    padding: 40px;
    width:calc(50% - 80px);

    text-align: justify;
  }

  .image {
    display: block;

    height: 100%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

h1,
p {
  margin: 0;
}

.left {
  flex-direction: row-reverse;
}

@media screen and (max-width: 905px) {
  .container {
    height: auto;

    & > .text,
    & > .image {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
