<template>
  <header-view/>
  <router-view/>
  <footer-view/>
</template>

<script>
import HeaderView from '@/components/HeaderView.vue';
import FooterView from '@/components/blocks/FooterView.vue';

export default {
  components: {
    HeaderView,
    FooterView,
  },
  computed: {
    width: {
      get() {
        console.log(window.screen.width);
        return window.screen.width;
      },
    },
  },
  watch: {
    $route() {
      if (window.innerWidth <= 446) {
        const top = document.querySelector('.top');
        const img = document.querySelector('.hamburger > img');

        top.style.display = 'none';
        top.classList.remove('open');
        img.src = require('@/assets/home/menu.svg');
        document.querySelector('#app').style.overflow = 'auto';
      }
    },
  },
};
</script>

<style lang="scss">

html {
  height: 100%;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $ai-color-black;

  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  height: 100%;
}

h3 {
  font-weight: 800;
  font-size: 18px;
}
</style>
