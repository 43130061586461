import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4e8bfbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container left" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_bar = _resolveComponent("tab-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.info.title !== undefined)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.info.title), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_tab_bar, {
        tabs: _ctx.info.tabs,
        number: 1
      }, null, 8, ["tabs"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.details.body), 1)
    ]),
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle({'backgroundImage': `url(${_ctx.details.image})`})
    }, null, 4)
  ]))
}