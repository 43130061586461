import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.theme)
  }, [
    _createVNode(_component_router_link, {
      class: "router-link",
      to: "/"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Home ")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "router-link",
      to: "/solutions"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Solutions ")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "router-link",
      to: "/team"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Team ")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "router-link",
      to: "/contact-us"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Contact Us ")
      ]),
      _: 1
    })
  ], 2))
}