import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61aaed3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "links-container" }
const _hoisted_5 = { id: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_link_container = _resolveComponent("link-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: "aility logo"
      }, null, 8, _hoisted_3),
      _createVNode(_component_nav_bar, { theme: "light" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, "© " + _toDisplayString(_ctx.year) + " Aility GmbH. All Rights Reserved.", 1),
      _createVNode(_component_link_container)
    ])
  ]))
}