<template>
  <div class="container">
    <div>
      <h1>{{ info.title }}</h1>
      <tab-bar :tabs="info.tabs" :is-divided="true" :number="2"/>
    </div>

    <div class="details">
      <img :src="details.image" alt="image"/>

      <div class="text">
        <div>{{ details.body }}</div>

<!--        <div class="learn-more">-->
<!--          <a :href="details.url">-->
<!--            Learn More<span class="material-symbols-rounded">&#xE941;</span>-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabBar from '@/components/items/TabBar.vue';

export default defineComponent({
  name: 'BlockTabsView',
  components: { TabBar },
  props: {
    info: Object,
  },
  data() {
    return {
      details: this.info?.tabs !== undefined ? this.info?.tabs[0] : Object,
    };
  },
  mounted() {
    const tabs = [...document.getElementsByClassName('tab-bar')[1].children];
    tabs[0].classList.add('selected');

    this.$store.watch(
      (state) => state.current_tab_2,
      // eslint-disable-next-line no-return-assign
      (value) => {
        if (this.info?.tabs !== undefined) this.details = this.info?.tabs[value];

        tabs.map((el) => el.classList.remove('selected'));
        tabs[value].classList.add('selected');
      },
    );
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 40px;
}

.details {
  display: flex;
  flex-direction: row;

  align-items: start;
  gap: 40px;

  width: 60%;

  padding: 40px;

  img {
    width: 50%;
  }

  & > .text {
    text-align: justify;
  }
}

a {
  color: $ai-color-secondary;
  text-decoration: none;

  & > span {
    font-size: 20px;
    transform: translate(5px, 5px);
  }

  &:hover {
    text-decoration: underline;
  }
}

.learn-more {
  display: flex;
  flex-direction: row;

  align-items: center;

  margin-top: 10px;
}

h1 {
  margin-top: 0;
  margin-bottom: 40px;
}

@media screen and (max-width: 836px) {
  .details {
    flex-flow: column;

    & > img {
      width: 100%;
    }
  }
}
</style>
