<template>
  <div class="banner">
    <span>
      <h3>Aility</h3>

      <h3>
        Bringing <span class="highlight">AI</span> to the
        Hospita<span class="highlight">lity</span> industry
      </h3>
    </span>

    <p>
      We enable hoteliers to make sophisticated data <br/> driven decisions based
      on people flow analytics.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BannerView',
});
</script>

<style scoped lang="scss">
.banner {
  padding: 30px;

  color: $ai-color-white;
  background: $ai-color-primary;

  h3 {
    margin: 0;

    &:nth-of-type(1) {
      margin-bottom: 5px;
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;

    font-size: 14px;
  }
}

.highlight {
  font-style: italic;
  color: $ai-color-secondary;
}
</style>
