import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: () => import(/* webpackChunkName: "solutions" */ '../views/SolutionsView.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/TeamView.vue'),
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUsView.vue'),
  },
  {
    path: '/under-construction',
    name: 'under-construction',
    component: () => import(
      /* webpackChunkName: "under construction" */ '../views/UnderConstructionView.vue'
    ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
