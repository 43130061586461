import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-040791da"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    id: _ctx.index,
    class: "tab-bar-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeTab && _ctx.changeTab(...args)))
  }, [
    (_ctx.icon !== undefined)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          innerHTML: _ctx.icon,
          class: "material-symbols-rounded"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
  ], 8, _hoisted_1))
}